import React from 'react';

const RusaPage = () => {
  return (
    <div>
      <h2>Message</h2>
	  <p style={{ textAlign: 'justify', marginLeft: '10px', marginRight: '10px' }}>Before launching its services, Pitics Technologies is providing detailed information regarding the website links and the process behind their generation.

Pitics Technologies has been specifically designed to offer an additional service for students. With a focus on supporting educational advancements, Pitics recognizes the persistent efforts of the Government of India to develop and improve the education system in India.

In line with this mission, Pitics Technologies aims to align its services helping students gain access to valuable resources. The website links and names generated by Pitics are crafted based on these educational schemes, ensuring they cater to the government's vision of enhancing the educational landscape for all.</p>
      <p></p>
	  <p></p>
	  <p></p>
	  <div>
	  <p>  With Regards,</p>
	  <p>Pitics Technologies</p>
	  </div>
    </div>
  );
}

export default RusaPage;
