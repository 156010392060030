import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Rusa1 from './pages/Rusa1';
import Nibha1 from './pages/Nibha1';
import Midam1 from './pages/Midam1';
import Obla1 from './pages/Obla1'; // Fixed typo
import Sasia1 from './pages/Sasia1';
import Contact1 from './pages/Contact1';

import "./App.css";
import { getAuth, onAuthStateChanged, setPersistence, browserSessionPersistence, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { initializeApp } from "firebase/app";
import Footer from "./template/Footer";
import LeftSidebar from './template/LeftSidebar';
import RightSidebar from './template/RightSidebar';
import Header from "./template/Header";
import SEOComponent from "./components/SEOComponent";

// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDAAc0oh_TDVAZJ6RZTEh0vokOeBOtbBLs",
  authDomain: "mainprojtest.firebaseapp.com",
  projectId: "mainprojtest",
  storageBucket: "mainprojtest.firebasestorage.app",
  messagingSenderId: "189172828486",
  appId: "1:189172828486:web:3c085240d1ebea21bbbd47",
  measurementId: "G-6MRS7BNQJS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

//const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

// Set session-based persistence to avoid tracking protection issues
const setAuthPersistence = async () => {
  try {
    await setPersistence(auth, browserSessionPersistence);
    console.log("Session persistence enabled");
  } catch (error) {
    console.error("Error setting persistence:", error);
  }
};
setAuthPersistence();

// Function to check if storage is available
function isStorageAvailable(type) {
  try {
    const storage = window[type];
    const testKey = "__test";
    storage.setItem(testKey, "1");
    storage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
}

console.log("LocalStorage available:", isStorageAvailable("localStorage"));
console.log("Cookies enabled:", navigator.cookieEnabled);

if (!isStorageAvailable("localStorage") || !navigator.cookieEnabled) {
  alert(
    "Your browser's tracking protection may be blocking login. Try disabling it or using another browser."
  );
}

const App = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [keywords, setKeywords] = useState("");

  // Fetch keywords from the external text file
  useEffect(() => {
    fetch("/seo-keywords.txt")
      .then((response) => response.text())
      .then((data) => {
        setKeywords(data);  // Set keywords fetched from the text file
      })
      .catch((error) => console.error("Error fetching SEO keywords:", error));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth > 1024) {
        setMobileNavOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      console.log("Auth state changed:", currentUser);
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log("Signed in:", result.user);
      })
      .catch((error) => {
        console.error("Login failed:", error);
      });
  };

  // Close mobile nav on link click
  const handleMobileLinkClick = () => {
    setMobileNavOpen(false);
  };

  return (
    <Router>
      <div className="app">
        <Header
          windowWidth={windowWidth}
          isMobileNavOpen={isMobileNavOpen}
          setMobileNavOpen={setMobileNavOpen}
          user={user}
          auth={auth}
          onSignIn={handleSignIn}
        />

        {windowWidth <= 1024 && isMobileNavOpen && (
          <nav className="mobile-nav">
            <button className="close-nav" onClick={() => setMobileNavOpen(false)}>
              ✖
            </button>
            <ul className="nav-links">
              <li><Link to="/" onClick={handleMobileLinkClick}>Home</Link></li>
              <li><Link to="/rusa1" onClick={handleMobileLinkClick}>Rusa</Link></li>
              <li><Link to="/nibha1" onClick={handleMobileLinkClick}>Nibha</Link></li>
              <li><Link to="/midam1" onClick={handleMobileLinkClick}>Midam</Link></li>
              <li><Link to="/obla1" onClick={handleMobileLinkClick}>Obla</Link></li>
              <li><Link to="/sasia1" onClick={handleMobileLinkClick}>Sasia</Link></li>
              <li><Link to="/contact1" onClick={handleMobileLinkClick}>Contact</Link></li>
            </ul>
          </nav>
        )}

        <main className="main-content">
  {windowWidth > 1024 ? (
    <>
      <LeftSidebar />
      <div className="center-content">
        <Routes>
		<Route path="/" element={<Contact1 />} /> {/* Home route */}
          <Route path="/rusa1" element={<Rusa1 />} />
          <Route path="/nibha1" element={<Nibha1 />} />
          <Route path="/midam1" element={<Midam1 />} />
          <Route path="/obla1" element={<Obla1 />} />
          <Route path="/sasia1" element={<Sasia1 />} />
          <Route path="/contact1" element={<Contact1 />} />
        </Routes>
      </div>
      <RightSidebar />
    </>
  ) : (
    <>
      <div className="mobile-header-sidebar">
        <LeftSidebar />
      </div>
      <div className="center-content">
       <Routes>
  <Route path="/" element={<Contact1 />} /> {/* Home route */}
  <Route path="/rusa1" element={<Rusa1 />} />
  <Route path="/nibha1" element={<Nibha1 />} />
  <Route path="/midam1" element={<Midam1 />} />
  <Route path="/obla1" element={<Obla1 />} />
  <Route path="/sasia1" element={<Sasia1 />} />
  <Route path="/contact1" element={<Contact1 />} />
</Routes>

      </div>
      <div className="mobile-footer-sidebar">
        <RightSidebar />
      </div>
    </>
  )}
</main>


       
        <Footer />
      </div>

      <SEOComponent
        title="Pitics"
        description="Pitics Just Get And Try"
        keywords={keywords}
      />
    </Router>
  );
};

export default App;
