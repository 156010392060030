import React from "react";

const RightSidebar = () => (
  <div className="right-sidebar">
    {/* Add your sidebar content here */}
    
  </div>
);

export default RightSidebar;
