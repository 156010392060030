import React from 'react';

const RusaPage = () => {
  return (
    <div>
      <h2>Nibha</h2>
      <p style={{ textAlign: 'justify', marginLeft: '10px', marginRight: '10px' }}>Nipun Bharat was launched in 2021 by the Government of India. Its purpose is to ensure the holistic development and foundational literacy and numeracy of children in the early grades (grades 1 to 3) across the country. The initiative aims to address the learning gaps, especially those exacerbated by the COVID-19 pandemic, and ensure that every child has the foundational skills needed for further learning.

The program focuses on improving the quality of education in primary schools through the use of new teaching techniques, a robust curriculum, and teacher training programs. Nipun Bharat stands for National Initiative for Proficiency in Reading with Understanding and Numeracy.</p>
    </div>
  );
}

export default RusaPage;
