import React from 'react';

const RusaPage = () => {
  return (
    <div>
      <h2>Obla</h2>
      <p style={{ textAlign: 'justify', marginLeft: '10px', marginRight: '10px' }}>Operation Blackboard was launched by the Government of India in 1987 as a part of a comprehensive strategy to improve the quality of education in primary schools, especially in rural and remote areas. The main aim of this initiative was to provide basic infrastructure and resources to schools to ensure a better learning environment for children.

Key Objectives of Operation Blackboard:
Provision of Basic Infrastructure: The scheme focused on equipping schools with essential infrastructure, such as blackboards, furniture, and proper classrooms to create an environment conducive to learning.

Improving Teacher Quality: It emphasized the importance of improving the quality of teaching by ensuring that schools had an adequate number of teachers. It also aimed to provide training to teachers for better pedagogy.

Strengthening Primary Education: Operation Blackboard aimed at making primary education more accessible and effective by ensuring that schools had the basic facilities to meet educational standards.

Focus on Rural Areas: A significant part of the initiative focused on strengthening schools in rural and remote areas, where access to proper educational infrastructure and resources was limited.

Components of the Scheme:
Provision of Essential Teaching Materials: Blackboards, charts, and other teaching aids were provided to enhance the quality of teaching.

Infrastructure Development: Schools were provided with basic infrastructure, including classrooms, furniture, and sanitation facilities.

Teacher Recruitment: Efforts were made to recruit sufficient teachers to meet the needs of the expanding primary education system.

Impact:
Operation Blackboard contributed significantly to improving the basic infrastructure and educational environment in primary schools, especially in underserved areas. It was an important step in the government’s ongoing efforts to improve the quality of primary education in India. Over the years, the scheme evolved into other programs, like the Sarva Shiksha Abhiyan and later the Right to Education Act, which further built on its foundational goals.



</p>
    </div>
  );
}

export default RusaPage;
