import React from 'react';

const RusaPage = () => {
  return (
    <div>
      <h2>Sasia</h2>
      <p style={{ textAlign: 'justify', marginLeft: '10px', marginRight: '10px' }}>**Sarva Shiksha Abhiyan (SSA)** was launched by the **Government of India** in **2000** as a flagship program aimed at the universalization of elementary education. The program's primary objective was to ensure that every child, aged 6 to 14, has access to free and quality education in a school within their neighborhood.

### Key Objectives of Sarva Shiksha Abhiyan:

1. **Universal Access to Education**: SSA aimed to bring every child to school, ensuring that education was accessible to all, especially to marginalized and underprivileged children, including those in rural, tribal, and remote areas.

2. **Quality Education**: The program not only focused on enrollment but also on improving the quality of education provided, through curriculum reforms, teacher training, and enhancing learning outcomes.

3. **Retention of Children**: SSA worked towards reducing dropout rates, particularly among girls and children from socially and economically disadvantaged backgrounds, and ensuring that children stay in school until the completion of their elementary education.

4. **Infrastructure Development**: The program focused on building and upgrading school infrastructure, such as classrooms, toilets, drinking water facilities, and playgrounds, to create a conducive environment for learning.

5. **Community Participation**: SSA encouraged the active involvement of local communities in the management of schools. Village Education Committees (VECs) and School Management Committees (SMCs) played an important role in monitoring and improving the quality of education in schools.

### Key Features of SSA:

1. **Free and Compulsory Education**: SSA guaranteed free education for children, ensuring that no child would be denied an education due to financial constraints. This was aligned with the **Right to Education (RTE) Act**, passed in 2009.

2. **Teacher Training and Recruitment**: The program focused on improving the quality of education by recruiting and training teachers to meet the required educational standards. It also emphasized professional development for teachers.

3. **Focus on Inclusivity**: SSA aimed at bringing marginalized groups, such as girls, children with disabilities, and children from scheduled castes (SCs), scheduled tribes (STs), and other minority communities, into the mainstream education system.

4. **Curriculum and Pedagogical Reforms**: The program introduced new teaching methods and learning materials that were more relevant to the needs of children, with a focus on child-centered learning.

### Impact of SSA:
- **Increased Enrollment**: SSA contributed significantly to increased enrollment in elementary schools and improved gender parity in education, particularly in rural and remote areas.
- **Improved Infrastructure**: Schools saw improvements in physical infrastructure, including the construction of new classrooms and provision of basic facilities like toilets, drinking water, and midday meals.
- **Quality Improvements**: Teacher training programs under SSA helped improve teaching standards, and the emphasis on child-friendly, activity-based learning enhanced educational outcomes.

In 2018, the **Rashtriya Madhyamik Shiksha Abhiyan (RMSA)** was introduced to focus on secondary education, and SSA's objectives were gradually integrated into the **Samagra Shiksha Abhiyan**, which now covers the entire education cycle from pre-school to higher secondary education.

Overall, Sarva Shiksha Abhiyan played a crucial role in India's journey towards universalizing education, promoting inclusivity, and improving both the reach and quality of elementary education.</p>
    </div>
  );
}

export default RusaPage;
