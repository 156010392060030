import React from 'react';

const RusaPage = () => {
	
	
  return (
    <div>
      <h2>Rusa</h2>
    <p style={{ textAlign: 'justify', marginLeft: '10px', marginRight: '10px' }}> RUSA (Rashtriya Uchchatar Shiksha Abhiyan) is a significant initiative launched in 2013 by the Indian government to enhance the quality of higher education in India. Its main objective is to provide financial assistance to state universities and colleges, which would help improve infrastructure, teaching standards, and research capabilities. The scheme aims to increase access to higher education, improve the quality of education, and ensure equity by focusing on marginalized and underrepresented sections of society.</p>
    </div>
  );
}

export default RusaPage;
