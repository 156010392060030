import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";

import "./Header.css";

// Set up Google Auth provider
const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

const Header = ({ windowWidth, isMobileNavOpen, setMobileNavOpen, user, auth }) => {
  const [isAccountMenuOpen, setAccountMenuOpen] = useState(false);
  const [isSigningOut, setIsSigningOut] = useState(false);
  const [showSignOutConfirm, setShowSignOutConfirm] = useState(false);
  const dropdownRef = useRef(null);

  const handleSignIn = async () => {
    try {
      if (/Mobi|Android/i.test(navigator.userAgent)) {
        await signInWithPopup(auth, provider); // Using popup for mobile for better UX
      } else {
        await signInWithPopup(auth, provider); // For desktop
      }
    } catch (error) {
      console.error("Sign-in error", error);
      alert("Sign-in failed. Please try again.");
    }
  };

  const handleSignOut = async () => {
    setIsSigningOut(true);
    try {
      await signOut(auth);
      setAccountMenuOpen(false);
    } catch (error) {
      console.error("Sign-out error", error);
    } finally {
      setIsSigningOut(false);
      setShowSignOutConfirm(false);
    }
  };

  return (
    <header className="header">
      <div className="header-content">
        {windowWidth <= 1024 && (
          <button
            className="mobile-nav-toggle"
            onClick={() => setMobileNavOpen(!isMobileNavOpen)}
          >
            ☰
          </button>
        )}

        <img 
          src="/banner.png" 
          alt="Banner" 
          className="banner-image1" 
          width="600" 
          height="60" 
        />

        {windowWidth > 1024 && (
          <nav className="desktop-nav desktop-nav-inline">
            <ul className="nav-links">
              <li><Link to="/contact1">Home</Link></li> {/* Default home link set to "/rusa1" */}
              <li><Link to="/rusa1">Rusa</Link></li>
              <li><Link to="/nibha1">Nibha</Link></li>
              <li><Link to="/midam1">Midam</Link></li>
              <li><Link to="/obla1">Obla</Link></li>
              <li><Link to="/sasia1">Sasia</Link></li>
              <li><Link to="/contact1">Contact</Link></li>
            </ul>
          </nav>
        )}

        <div className="auth-section">
          {user ? (
            <div className="account-menu">
              <button
                onClick={() => setAccountMenuOpen(!isAccountMenuOpen)}
                className="account-button"
              >
                My Account
              </button>
              <div
                className={`account-dropdown ${isAccountMenuOpen ? "active" : ""}`}
                ref={dropdownRef}
              >
                <button
                  className="close-btn"
                  onClick={() => setAccountMenuOpen(false)}
                >
                  ✖
                </button>
                <p>Hi, {user.displayName || "User"}</p>
                <button onClick={() => setShowSignOutConfirm(true)}>Sign Out</button>
              </div>
            </div>
          ) : (
            <div className="signin-wrapper">
              {windowWidth <= 1024 ? (
                <span className="signin-pro-text">pro</span> // "pro" only on mobile
              ) : (
                <span className="signin-pro-text-desktop">pro</span> // "pro" only on desktop
              )}
              <button onClick={handleSignIn} className="signin-button-circle">
                {/* Optionally, you can add an icon here */}
              </button>
            </div>
          )}
        </div>

        {showSignOutConfirm && (
          <div className="signout-modal">
            <div className="modal-content">
              <p>Are you sure you want to sign out?</p>
              <button onClick={handleSignOut} disabled={isSigningOut}>
                {isSigningOut ? "Signing out..." : "Yes, Sign Out"}
              </button>
              <button onClick={() => setShowSignOutConfirm(false)}>Cancel</button>
            </div>
          </div>
        )}
      </div>
    </header>
	
  );
};

export default Header;
