import React from "react";

const Footer = () => (
  <footer className="footer">
    <p>Copyright &copy; 2025 Pitics Technologies. All rights reserved.</p>
	<p>Make In India</p>
	<p>Reach us at <a href="mailto:support@pitics.com" style={{ color: 'green' }}>support@pitics.com</a></p>



  </footer>
);

export default Footer;
