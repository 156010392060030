import React from 'react';

const RusaPage = () => {
  return (
    <div>
      <h2>Midam</h2>
      <p style={{ textAlign: 'justify', marginLeft: '10px', marginRight: '10px' }}>The Mid-Day Meal Scheme (MDMS) is a government initiative launched in India in 1995 with the primary aim of improving the nutritional status of school-age children, particularly those from disadvantaged backgrounds. The scheme provides free lunch to children in government and government-aided schools across the country.

The key objectives of the Mid-Day Meal Scheme include:

Improving Nutrition: The scheme ensures that children receive at least one nutritious meal during school hours, which helps in reducing malnutrition and improving overall health.

Encouraging School Attendance: By offering free meals, the scheme incentivizes parents to send their children to school, thus promoting higher enrollment and attendance rates.

Enhancing Learning Outcomes: Adequate nutrition is linked to better cognitive development, and the scheme aims to improve children's ability to concentrate and perform better in their studies.

Social Equity: The scheme promotes social equity by providing food to all children, irrespective of their caste, religion, or economic background, thereby fostering inclusivity in schools.

In recent years, the government has also taken steps to improve the quality and variety of meals, ensuring they meet the nutritional standards set for children. The scheme covers a vast number of children, and it has been one of the largest school meal programs in the world.</p>
    </div>
  );
}

export default RusaPage;
